.bnota{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: table;
    max-width: 500px;
    min-height: 300px;
    width: 95%;
    background-color: #e4e480;
    margin: auto;
}
.bnota .notah{
    height: 50px;
    line-height: 50px;
    font-size: 14px;
    font-weight: 700;
    padding: 0 30px;
    max-width: 500px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.bnota .notab{
    font-size: 12px;
    background-color: #FFFF8D;
}
.notab{
    margin-top: 0;
}
.notabs{
    overflow-y: auto; 
    max-height: 300px;
    padding: 30px;
}
.bnota .notaf{
    height: 35px;
    padding: 0 30px;
}
.notae,.notag{
    text-decoration: none;
    color: #767641;
    display: inline-block;
    width: 49%;
    height: 35px;
    line-height: 35px; 
}
.notag{
    cursor: pointer;
}
.notag:hover, .notae:hover{
    color: #ff6859;
}
.contbot{
    margin: auto;
    display: table;
    cursor: pointer;
}
.notac{
    position: absolute;
    right: 0;
    width: 74px;
    height: 50px;
    text-align: center;
    cursor: pointer;
}
.h400{
    height: 400px;
}
.notaBtn{
    float: left;
    margin-right: 8px;
}

@media screen and (max-width: 520px) {
    .bnota .notah{
        max-width: 320px;
    }
}
@media screen and (max-width: 380px) {
    .bnota .notah{
        max-width: 200px;
    }
}