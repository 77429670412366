.scrolllin{
	height: 100%;
	width: 2px;
	position: absolute;
	top:0;
	left: 20px;
	z-index: -1;
	/*margin-top: 6px;*/
}
.scroll_bg{
	height: 100%;
	width: 2px;
	background-color: #CCCCCC;
	position: absolute;
	top:0;
	z-index: -1;
}
.scroll_avance{
	position: absolute;
	width: 2px;
	top:0;
	height: 0;
	background: #00aae4; /* Old browsers */
	background: -moz-linear-gradient(top, #00aae4 0%, #00aae4 90%, #cccccc 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, #00aae4 0%,#00aae4 90%,#cccccc 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #00aae4 0%,#00aae4 90%,#cccccc 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00aae4', endColorstr='#cccccc',GradientType=0 ); /* IE6-9 */
} 
.vscroll{
	position: fixed;
	height: 100%;
	top:52px;
	width: 100%;
	display: table;
	padding: 13px;
	z-index: 41;
}
.vscroll .trow span{
	font-size: 14px;
	padding: 0;
	font-weight: normal;
	display: table-cell;
	padding-left: 20px;
	/* white-space: nowrap; */
	vertical-align: bottom;
	cursor: pointer;
}
.vscroll .trow:hover .ist, .vscroll .trow:hover .itic{
	/* it_st_hover.svg */
	background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkNhcGFfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHdpZHRoPSIxMC4xMTdweCIgaGVpZ2h0PSIxMC40MDZweCIgdmlld0JveD0iMCAwIDEwLjExNyAxMC40MDYiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDEwLjExNyAxMC40MDYiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGVsbGlwc2UgZmlsbD0iI2ZmNjg1OSIgY3g9IjUuMDgyIiBjeT0iNS4yMDMiIHJ4PSI1LjA4MiIgcnk9IjUuMjAzIi8+DQo8L3N2Zz4NCg==);
}
.vscroll .trow:hover .ithc {
	background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkNhcGFfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHdpZHRoPSIxNS4yNDZweCIgaGVpZ2h0PSIxNS42MDlweCIgdmlld0JveD0iMCAwIDE1LjI0NiAxNS42MDkiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDE1LjI0NiAxNS42MDkiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGVsbGlwc2UgZmlsbD0iI2ZmNjg1OSIgY3g9IjcuNjIzIiBjeT0iNy44MDQiIHJ4PSI3LjYyMyIgcnk9IjcuODA1Ii8+DQo8ZWxsaXBzZSBmaWxsPSIjRkZGRkZGIiBjeD0iNy42NDEiIGN5PSI3LjgwNCIgcng9IjMuNjk2IiByeT0iMy43ODQiLz4NCjwvc3ZnPg0K);
}
.ith{
	/* it_th_g.svg */
	background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkNhcGFfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHdpZHRoPSIxNS4yNDZweCIgaGVpZ2h0PSIxNS42MDlweCIgdmlld0JveD0iMCAwIDE1LjI0NiAxNS42MDkiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDE1LjI0NiAxNS42MDkiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGVsbGlwc2UgZmlsbD0iI0NDQ0NDQyIgY3g9IjcuNjIzIiBjeT0iNy44MDQiIHJ4PSI3LjYyMyIgcnk9IjcuODA1Ii8+DQo8ZWxsaXBzZSBmaWxsPSIjRkZGRkZGIiBjeD0iNy42NDEiIGN5PSI3LjgwNCIgcng9IjMuNjk2IiByeT0iMy43ODQiLz4NCjwvc3ZnPg0K);
	background-repeat: no-repeat;
	background-position: 0 bottom;
}
.iti, .ist{
	/* it_st_g.svg */
	background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkNhcGFfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHdpZHRoPSIxMC4xNjRweCIgaGVpZ2h0PSIxMC40MDZweCIgdmlld0JveD0iMCAwIDEwLjE2NCAxMC40MDYiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDEwLjE2NCAxMC40MDYiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGVsbGlwc2UgZmlsbD0iI0NDQ0NDQyIgY3g9IjUuMDgyIiBjeT0iNS4yMDMiIHJ4PSI1LjA4MiIgcnk9IjUuMjAzIi8+DQo8L3N2Zz4NCg==);
	background-repeat: no-repeat;
	background-position: 3px bottom;
}  
.ith, .iti, .ist{
	color:#666666;
}
.ithc, .itic, .istc{
	color:#009EDE;
}  
.ithc{
	/* it_th.svg */
	background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkNhcGFfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHdpZHRoPSIxNS4yNDZweCIgaGVpZ2h0PSIxNS42MDlweCIgdmlld0JveD0iMCAwIDE1LjI0NiAxNS42MDkiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDE1LjI0NiAxNS42MDkiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGVsbGlwc2UgZmlsbD0iIzAwYWFlNCIgY3g9IjcuNjIzIiBjeT0iNy44MDQiIHJ4PSI3LjYyMyIgcnk9IjcuODA1Ii8+DQo8ZWxsaXBzZSBmaWxsPSIjRkZGRkZGIiBjeD0iNy42NDEiIGN5PSI3LjgwNCIgcng9IjMuNjk2IiByeT0iMy43ODQiLz4NCjwvc3ZnPg0K);
}
.itic, .istc{
	/* it_st.svg */
	background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkNhcGFfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHdpZHRoPSIxMC4xMTZweCIgaGVpZ2h0PSIxMC40MDZweCIgdmlld0JveD0iMCAwIDEwLjExNiAxMC40MDYiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDEwLjExNiAxMC40MDYiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGVsbGlwc2UgZmlsbD0iIzAwYWFlNCIgY3g9IjUuMDgyIiBjeT0iNS4yMDMiIHJ4PSI1LjA4MiIgcnk9IjUuMjAzIi8+DQo8L3N2Zz4NCg==);
}
.bgapple{
	position: absolute;
	/* top: 0; */
	top: -52px;
	bottom: 0;
	width: 100%;
	height: 1200px;
	background-color: #eee;
	margin-left: -13px;
	z-index: -4;
}
.tooltip{
	position: fixed;
	right: -50px;
	background-color: #ff6859;
	color: #fff;
	z-index: 87;
	padding: 2px 7px;
	font-size: 11px;
	display: none;
}
.wrapScroll{
	display: table;
	height: 100%;
}