footer{
	width: 100%;
	height: 52px;
	background-color: #fff;
	position: fixed;
	bottom: 0;
	z-index: 55;
}
.fizq{
	width: 100%;
	height: 52px;
	/*foo_izq.svg*/
	background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkNhcGFfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHdpZHRoPSIyNHB4IiBoZWlnaHQ9IjI0cHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMjQgMjQiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+DQoJPHBhdGggZmlsbD0iIzZGNkY2RiIgZD0iTTEzLjU0OSwxNS4zMDJMMTAuMjQ4LDEybDMuMzAxLTMuMzAxYzAuMTkxLTAuMTkxLDAuMTkxLTAuNDk2LDAtMC42ODhzLTAuNDk2LTAuMTkxLTAuNjg4LDBsLTMuNjQ1LDMuNjQ2DQoJCUM5LjEyNCwxMS43NSw5LjA3NCwxMS44NzMsOS4wNzQsMTJjMCwwLjEyOCwwLjA1LDAuMjUxLDAuMTQzLDAuMzQ0bDMuNjQ1LDMuNjQ2YzAuMDk0LDAuMDkzLDAuMjIxLDAuMTQyLDAuMzQ0LDAuMTQyDQoJCXMwLjI1LTAuMDQ5LDAuMzQ0LTAuMTQyQzEzLjczNCwxNS44MDMsMTMuNzM0LDE1LjQ5MywxMy41NDksMTUuMzAyeiIvPg0KCTxwYXRoIGZpbGw9IiM2RjZGNkYiIGQ9Ik0xMiwwQzUuMzgzLDAsMCw1LjM4MywwLDEyczUuMzgzLDEyLDEyLDEyYzYuNjE3LDAsMTItNS4zODMsMTItMTJTMTguNjE3LDAsMTIsMHogTTE5LjY3LDE5LjY2OQ0KCQljLTIuMDcsMi4wNjktNC43OTMsMy4yMDktNy42NywzLjIwOXMtNS42LTEuMTQtNy42NjktMy4yMDlTMS4xMjIsMTQuODc2LDEuMTIyLDEyYzAtMi44NzcsMS4xMzktNS42LDMuMjA4LTcuNjcNCgkJQzYuNCwyLjI2Miw5LjEyMywxLjEyMiwxMiwxLjEyMnM1LjYsMS4xNCw3LjY3LDMuMjA4YzIuMDY4LDIuMDcsMy4yMDcsNC43OTMsMy4yMDcsNy42N0MyMi44NzcsMTQuODc2LDIxLjczOCwxNy42LDE5LjY3LDE5LjY2OXoiDQoJCS8+DQo8L2c+DQo8L3N2Zz4NCg==);
	background-position: right center;
	background-repeat: no-repeat;
	cursor: pointer;
}
.fder{
	width: 100%;
	height: 52px;
	/*foo_der.svg*/
	background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkNhcGFfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHdpZHRoPSIyNHB4IiBoZWlnaHQ9IjI0cHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMjQgMjQiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+DQoJPHBhdGggZmlsbD0iIzZGNkY2RiIgZD0iTTEwLjQ1MSw4LjY5OEwxMy43NTIsMTJsLTMuMzAxLDMuMzAxYy0wLjE5MSwwLjE5MS0wLjE5MSwwLjQ5NiwwLDAuNjg4czAuNDk2LDAuMTkxLDAuNjg4LDBsMy42NDUtMy42NDYNCgkJYzAuMDkzLTAuMDkzLDAuMTQzLTAuMjE2LDAuMTQzLTAuMzQzYzAtMC4xMjgtMC4wNS0wLjI1MS0wLjE0My0wLjM0NGwtMy42NDUtMy42NDZjLTAuMDk0LTAuMDkzLTAuMjIxLTAuMTQyLTAuMzQ0LTAuMTQyDQoJCXMtMC4yNSwwLjA0OS0wLjM0NCwwLjE0MkMxMC4yNjYsOC4xOTgsMTAuMjY2LDguNTA3LDEwLjQ1MSw4LjY5OHoiLz4NCgk8cGF0aCBmaWxsPSIjNkY2RjZGIiBkPSJNMTIsMjRjNi42MTYsMCwxMi01LjM4MywxMi0xMlMxOC42MTYsMCwxMiwwQzUuMzgzLDAsMCw1LjM4MywwLDEyUzUuMzgzLDI0LDEyLDI0eiBNNC4zMyw0LjMzMQ0KCQlDNi40LDIuMjYyLDkuMTIzLDEuMTIyLDEyLDEuMTIyczUuNiwxLjE0LDcuNjY5LDMuMjA5UzIyLjg3OCw5LjEyNCwyMi44NzgsMTJjMCwyLjg3Ny0xLjE0LDUuNi0zLjIwOSw3LjY3DQoJCUMxNy42LDIxLjczOSwxNC44NzcsMjIuODc4LDEyLDIyLjg3OHMtNS42LTEuMTQtNy42Ny0zLjIwOEMyLjI2MiwxNy42LDEuMTIzLDE0Ljg3NywxLjEyMywxMkMxLjEyMyw5LjEyNCwyLjI2Miw2LjQwMSw0LjMzLDQuMzMxeg0KCQkiLz4NCjwvZz4NCjwvc3ZnPg0K);
	background-position: left center;
	background-repeat: no-repeat;
	cursor: pointer;
}
.disable{
	cursor: default;
}
.fcen{
	width: 56%;
	color: #666666;
	font-size: 15px;
	text-align: center;
	padding-left: 10px;
	padding-right: 10px;
	line-height: 18px;
}
.fcentx{
	max-width: 56%;
	max-height: 46px;
	padding-top: 3px;
	padding-bottom: 3px;
	padding-left: 7px;
	padding-right: 7px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	position: absolute;
	top: 16px;
	left: 22%;
	width: 100%;
}

.borg{
	border-top: 3px solid #ccc;
}
.upfooter{
	padding-bottom: 38px !important; 
}
.cw22{
	width: 22%;
}